<template>
    <div>
        <div
            v-if="showImage"
            class="custom-image">
            <img :src="image">
        </div>
        <div
            v-else
            class="custom-block">
            <img
                v-if="icon"
                :src="`/img/${iconName}.png`"
                class="custom-icon"
                alt="custom-icon">
            <div class="custom-inner">
                <div v-html="content" />
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        meta: {
            type: Object,
            required: true
        }
    },
    computed: {
        showImage() {
            return this.meta.showImage ?? false;
        },
        image() {
            return this.meta.image ?? '';
        },
        content() {
            return this.meta.content ?? '';
        },
        icon() {
            const icon = this.meta.textIcon ?? '';
            if (icon === 'None') { return '' }
            return icon;
        },
        iconName() {
            return this.icon.toLowerCase().split(' ').join('-');
        }
    }
};
</script>

<style lang="scss" scoped>
.custom-block {
    position: relative;
    white-space: pre-wrap;
    height: calc(100% - 30px);
    padding-top: 5px;
}
.custom-inner {
    background-color: #D8F0FB;
    border-radius: 25px;
    box-sizing: border-box;
    padding: 25px;
    width: 100%;
    overflow: hidden;
}
.custom-image {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        max-width: 100%;
        max-height: 280px;
        width: auto;
        height: auto;
    }
}
</style>
<style lang="scss">
.full {
    .custom-inner {
        max-height: 640px;
    }
    .custom-image img {
        max-height: 640px;
    }
}
.two-up, .two-one {
    .custom-inner {
        max-height: 290px;
    }
}
.four-up {
    .custom-inner {
        max-height: 290px;
    }
    .custom-image img {
        max-height: 300px;
    }
}
.custom-block {
    .ql-align-right {
        text-align: right;
    }
    .ql-align-center {
        text-align: center;
    }
    p {
        margin-bottom: 0;
    }
    ul, ol {
        margin-bottom: 5px;
    }
    h1, h2 {
        color: #4e555b;
    }
}
.custom-icon {
    max-width: 30px;
    height: auto;
    position: absolute;
    top: 0;
    left: -10px;
}
</style>